<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <div class="bg-white poppins position-relative min-h-100">
      <div
        class="modal-header py-3 align-center bg-white position-sticky top-0 left-0 zindex-5"
      >
        <h4 class="mb-0 font-weight-bolder poppins">Create GRN</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div class="row flex-grow-0 flex-shrink-0">
          <div class="col-12 h-75px">
            <v-datetime-picker
              label="GRN Date"
              v-model="formData.grn_date"
              :textFieldProps="{
                outlined: true,
                dense: true,
                'append-icon': 'mdi-calendar',
              }"
            />
          </div>
          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.remarks"
              label="Remarks"
              clearable
              outlined
              dense
            />
          </div>
        </div>
        <!--end::Body-->
        <!--begin::Actions-->
        <div
          class="my-4 min-h-75px d-flex flex-grow-1 align-end justify-center"
        >
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "GenerateGrnPopup",
  props: ["getDataFromApi"],
  data: () => ({
    dialog: false,
    id: null,
    asn_sku_ids: [],
    formData: { grn_date: null, remarks: "" },
  }),
  methods: {
    toggleModal(id = null, asn_sku_ids) {
      if (this.dialog) {
        this.id = null;
        this.asn_sku_ids = [];
        this.formData = { grn_date: null, remarks: "" };
        this.dialog = !this.dialog;
      } else {
        if (id) {
          this.id = id;
          this.asn_sku_ids = [...asn_sku_ids];
          this.dialog = true;
        }
      }
    },
    dateFormatter(date) {
      let d = date;
      return (
        [
          d.getFullYear(),
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        ].join("-") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
    submitForm() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let grn_date;
      if (this.formData.grn_date) {
        grn_date = this.dateFormatter(this.formData.grn_date);
      }
      let data = {
        id: this.id,
        warehouse_id: this.$store.getters.getSelectedWarehouse,
        asn_sku_ids: this.asn_sku_ids,
        grn_date,
        remarks: this.formData.remarks,
      };

      ApiService.post(
        "/warehouse_management/custom/standard_grn/generate-grn",
        data
      )

        .then(({ data }) => {
          const link = document.createElement("a");
          link.href = data.file;
          link.target = "_blank";
          link.download = "Generated.csv";
          link.click();
          Swal.fire({
            title: "Generated",
            text: "Standard GRN has been generated",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.getDataFromApi();
          this.toggleModal();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
};
</script>
