<template>
  <v-card class="mt-4">
    <v-toolbar color="primary h5 text-white">
      <div class="w-100 d-flex align-items-center justify-space-between">
        <div>
          SKU
        </div>
        <div class="">
          <button class="btn btn-light px-5 py-3 ls1" @click="generateGrn">
            Generate
          </button>
        </div>
      </div>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-tabs v-model="tabs" mobile-breakpoint="300">
        <v-tab>
          <h3 class="font-weight-medium ma-0 font-size-lg poppins">Main</h3>
        </v-tab>
        <v-tab>
          <h3 class="font-weight-medium ma-0 font-size-lg poppins">Pallet</h3>
        </v-tab>
        <v-tab>
          <h3 class="font-weight-medium ma-0 font-size-lg poppins">Batch</h3>
        </v-tab>
        <v-tab>
          <h3 class="font-weight-medium ma-0 font-size-lg poppins">Other</h3>
        </v-tab>
        <v-tab>
          <h3 class="font-weight-medium ma-0 font-size-lg poppins">
            Additional
          </h3>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-data-table
            v-model="selected"
            item-key="id"
            show-select
            disable-sort
            :headers="headersForMainTab"
            :items="getItems"
            :server-items-length="total"
            class="elevation-0"
            disable-pagination
            hide-default-footer
          >
            <template
              v-slot:header.data-table-select="{
                props: { value },
                on: { input },
              }"
            >
              <div
                class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="allSelected"
                  @click="selectAll"
                />
              </div>
            </template>
            <template v-slot:item.data-table-select="{ item }">
              <div
                class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="selected"
                  :value="item"
                />
              </div>
            </template>

            <template v-slot:item.sku_id="{ value }">
              <span class="second-text"> {{ value }}</span>
            </template>

            <template v-slot:item.asn_sku_id="{ value }">
              <span class="second-text"> {{ value }}</span>
            </template>

            <template v-slot:item.sku_barcode="{ value }">
              <span class="second-text"> {{ value }}</span>
            </template>

            <template v-slot:item.description="{ value }">
              <span class="max-w-100px"> {{ value }}</span>
            </template>
            <template v-slot:item.status="{ value }">
              <StatusColumn :value="value" />
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item>
          <v-data-table
            v-model="selected"
            item-key="id"
            show-select
            disable-sort
            :headers="headersForPalletTab"
            :items="getItems"
            :server-items-length="total"
            class="elevation-0"
            disable-pagination
            hide-default-footer
          >
            <template
              v-slot:header.data-table-select="{
                props: { value },
                on: { input },
              }"
            >
              <div
                class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="allSelected"
                  @click="selectAll"
                />
              </div>
            </template>
            <template v-slot:item.data-table-select="{ item }">
              <div
                class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="selected"
                  :value="item"
                />
              </div>
            </template>

            <template v-slot:item.sku_barcode="{ value }">
              <span class="second-text"> {{ value }}</span>
            </template>

            <template v-slot:item.description="{ value }">
              <span class="max-w-100px"> {{ value }}</span>
            </template>

            <template v-slot:item.carton_number="{ value }">
              <span class="second-text"> {{ value }}</span>
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item>
          <v-data-table
            v-model="selected"
            item-key="id"
            show-select
            disable-sort
            :headers="headersForBatchTab"
            :items="getItems"
            :server-items-length="total"
            class="elevation-0"
            disable-pagination
            hide-default-footer
          >
            <template
              v-slot:header.data-table-select="{
                props: { value },
                on: { input },
              }"
            >
              <div
                class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="allSelected"
                  @click="selectAll"
                />
              </div>
            </template>
            <template v-slot:item.data-table-select="{ item }">
              <div
                class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="selected"
                  :value="item"
                />
              </div>
            </template>

            <template v-slot:item.sku_barcode="{ value }">
              <span class="second-text"> {{ value }}</span>
            </template>

            <template v-slot:item.description="{ value }">
              <span class="max-w-100px"> {{ value }}</span>
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item>
          <v-data-table
            v-model="selected"
            item-key="id"
            show-select
            disable-sort
            :headers="headersForOtherTab"
            :items="getItems"
            :server-items-length="total"
            class="elevation-0"
            disable-pagination
            hide-default-footer
          >
            <template
              v-slot:header.data-table-select="{
                props: { value },
                on: { input },
              }"
            >
              <div
                class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="allSelected"
                  @click="selectAll"
                />
              </div>
            </template>
            <template v-slot:item.data-table-select="{ item }">
              <div
                class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="selected"
                  :value="item"
                />
              </div>
            </template>

            <template v-slot:item.sku_barcode="{ value }">
              <span class="second-text"> {{ value }}</span>
            </template>

            <template v-slot:item.description="{ value }">
              <span class="max-w-100px"> {{ value }}</span>
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item>
          <v-data-table
            v-model="selected"
            item-key="id"
            show-select
            disable-sort
            :headers="headersForAdditionalTab"
            :items="getItems"
            :server-items-length="total"
            class="elevation-0"
            disable-pagination
            hide-default-footer
          >
            <template
              v-slot:header.data-table-select="{
                props: { value },
                on: { input },
              }"
            >
              <div
                class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="allSelected"
                  @click="selectAll"
                />
              </div>
            </template>
            <template v-slot:item.data-table-select="{ item }">
              <div
                class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="selected"
                  :value="item"
                />
              </div>
            </template>

            <template v-slot:item.sku_barcode="{ value }">
              <span class="second-text"> {{ value }}</span>
            </template>

            <template v-slot:item.description="{ value }">
              <span class="max-w-100px"> {{ value }}</span>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <GenerateGrnPopup ref="generateGrnPopup" :getDataFromApi="getDataFromApi" />
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
import StatusColumn from "@/own/components/datatable/StatusColumn.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import GenerateGrnPopup from "@/own/components/warehouseManagement/standardGrn/GenerateGrnPopup.vue";
import SwalService from "@/core/services/swal.service";

export default {
  name: "CreateGrnTabDataTable",
  components: { GenerateGrnPopup, StatusColumn },
  data: () => ({
    id: null,
    tabs: null,
    total: 0,
    options: {},
    items: [],
    selected: [],
    allSelected: false,
    // item_per_page_list: [20, 30, 40, 50],
  }),
  beforeDestroy() {
    this.selected = [];
    this.allSelected = false;
    this.options = {};
  },
  methods: {
    deleteItem(item) {
      const index = this.selected.findIndex((el) => el.id === item.id);
      index > -1 && this.selected.splice(index, 1);
    },
    selectOne(event) {
      this.allSelected && this.deleteItem(event.target.value);

      this.allSelected = false;
    },
    selectAll(event) {
      if (event.target.checked) {
        this.items.map((item) => {
          this.selected.push(item);
        });
      } else this.selected.splice(0, this.selected.length);
    },
    generateGrn() {
      if (this.selected && this.selected.length) {
        this.$refs.generateGrnPopup.toggleModal(
          this.id,
          this.selected.map((item) => item.id)
        );
      } else {
        SwalService.warningMessage({
          html: this.$t("please_select_at_least_one_record"),
        });
      }
    },
    setId(id) {
      this.id = id;
      this.getDataFromApi();
    },
    getDataFromApi() {
      if (!this.id) return;
      this.$store.commit(SET_PAGE_LOADING, true);

      let data = {
        id: this.id,
        warehouse_id: this.$store.getters.getSelectedWarehouse,
      };

      ApiService.post(
        "/warehouse_management/custom/standard_grn/create-grn",
        data
      )
        .then((response) => {
          this.total = response.data.data.length;
          this.items = response.data.data;
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
  computed: {
    getItems() {
      return this.items;
    },
    headersForMainTab() {
      const headers = [
        { text: "Line No", value: "line_no" },
        { text: "SKU barcode", value: "sku_barcode" },
        { text: "UOM", value: "uom" },
        { text: "Description", value: "description" },
        { text: "Expected quantity", value: "expected_quantity" },
        { text: "Received quantity", value: "received_qty" },
        { text: "Putaway quantity", value: "putaway_quantity" },
        { text: "Discrepancy", value: "discrepancy" },
        // { text: "Status", value: "status" },
      ];

      if (this.getItems?.length) {
        if (this.getItems[0].hasOwnProperty("asn_sku_id")) {
          headers.unshift({
            text: "Asn SKU ID",
            value: "asn_sku_id",
          });
        }
        if (this.getItems[0].hasOwnProperty("sku_id")) {
          headers.unshift({
            text: "SKU ID",
            value: "sku_id",
          });
        }
      }

      return headers;
    },
    headersForPalletTab() {
      return [
        { text: "SKU barcode", value: "sku_barcode" },
        { text: "Description", value: "description" },
        { text: "Carton Number", value: "carton_number" },
        { text: "Carton UOM", value: "carton_uom" },
        { text: "Carton Description", value: "carton_description" },
        { text: "Length", value: "carton_length" },
        { text: "Width", value: "carton_width" },
        { text: "Height", value: "carton_height" },
        { text: "Dimension UOM", value: "carton_dimension_uom" },
        { text: "Volume", value: "carton_volume" },
        { text: "Volume UOM", value: "carton_volume_uom" },
      ];
    },
    headersForBatchTab() {
      return [
        { text: "SKU barcode", value: "sku_barcode" },
        { text: "Description", value: "description" },
        { text: "Batch number", value: "batch_number" },
        { text: "Serial number", value: "serial_number" },
        { text: "Lot no", value: "lot_no" },
        { text: "Invoice no", value: "invoice_no" },
        { text: "PO no", value: "po_no" },
        { text: "MFG date", value: "mfg_date" },
        { text: "Expiry date", value: "expiry_date" },
        { text: "Location", value: "location" },
        { text: "Damaged", value: "damaged" },
        { text: "Damage reason", value: "damage_reason" },
      ];
    },
    headersForOtherTab() {
      return [
        { text: "SKU barcode", value: "sku_barcode" },
        { text: "Description", value: "description" },
        { text: "Net weight", value: "net_weight" },
        { text: "Gross weight", value: "gross_weight" },
        { text: "Volume", value: "volume" },
        { text: "User", value: "user" },
        { text: "HSCODE", value: "hs_code" },
        { text: "COO", value: "coo" },
        { text: "Roll No", value: "roll_no" },
        { text: "BL no", value: "bl_no" },
        { text: "Unit Rate", value: "unit_rate" },
        { text: "Currency", value: "currency" },
        { text: "Container no", value: "container_no" },
        { text: "Import entry no", value: "import_entry_no" },
        { text: "Custom entry no", value: "custom_entry_no" },
      ];
    },
    headersForAdditionalTab() {
      return [
        { text: "SKU barcode", value: "sku_barcode" },
        { text: "Description", value: "description" },
        { text: "VIN no", value: "vin_no" },
        { text: "Engine no", value: "engine_no" },
        { text: "Body model", value: "body_model" },
        { text: "Specification", value: "specification" },
        { text: "Drive", value: "drive" },
        { text: "Condition", value: "condition" },
        { text: "Key no", value: "key_no" },
        { text: "Engine capacity", value: "engine_capacity" },
        { text: "Manufacture year", value: "manufacture_year" },
      ];
    },
  },
  // watch: {
  //   options: {
  //     handler() {
  //       this.getDataFromApi();
  //     },
  //     deep: true,
  //   },
  // },
};
</script>
